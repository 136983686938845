import React from "react";
import {
  Card,
  Typography,
  Box,
  IconButton,
  Button,
  CardActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import ApiService from "../lib/api_service";
import { useSelector } from "react-redux";

const api = new ApiService();
export default function CampaignCard({ campaign, onDelete }) {
  const { user } = useSelector((st) => st.user);
  const handleDelete = () => {
    api
      .deleteRequest(`api/v1/campaigns/${campaign.id}`, user.token)
      .then(() => {
        onDelete(campaign.id);
      })
      .catch((error) => {
        console.error("Error deleting campaign:", error);
      });
  };

  return (
    <Card
      variant="elevation"
      sx={{
        p: 2,
        width: "100%",
        maxWidth: { xs: "100%", sm: "100%", md: "90%" },
        backgroundColor: "background.paper",
        borderRadius: 3,
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.2s",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {campaign.title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {campaign.description}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Typography variant="body1">
          Created Date: {new Date(campaign.createdAt).toLocaleDateString()}
        </Typography>
      </Box>

      <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body2" color="primary">
          Closed by {campaign.closedCount}{" "}
          {campaign.closedCount === 1 ? "user" : "users"}
        </Typography>{" "}
        <IconButton aria-label="delete" onClick={handleDelete}>
          <DeleteIcon color="error" />
        </IconButton>
      </CardActions>
    </Card>
  );
}
