import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { animated, useSpring } from "@react-spring/web";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TradingSignalCard from "../../components/TradingSignal";
import ApiService from "../../lib/api_service";
const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const api = new ApiService();

const SignalsPageAdmin = () => {
  const [signals, setSignals] = useState([]);
  const [timeframes, setTimeframes] = useState([]);
  const [signal, setSignal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false); // Modal state
  const [newSignal, setNewSignal] = useState({
    symbol: "",
    action: "",
    entryPrice: "",
    targetPrice: "",
    stopLoss: "",
    timeframe: "",
    confidenceLevel: "",
  });

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    fetchData();
    fetchTimeFrames();
  }, []);

  const fetchTimeFrames = async () => {
    setLoading(true);
    try {
      const response = await api.getRequest("api/v1/timeframes", user.token);
      setTimeframes(response.data);
    } catch (error) {
      console.error("Error fetching signals data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.getRequest("api/v1/trade-signals", user.token);
      setSignals(response.data.signals);
    } catch (error) {
      console.error("Error fetching signals data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBuySignal = (signal) => {
    console.log("Buying signal:", signal);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSignal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddSignal = async () => {
    try {
      await api.postRequestwithBearer(
        "api/v1/trade-signals",
        JSON.stringify(newSignal),
        user.token
      );
      setOpen(false); // Close the modal after adding the signal
      fetchData(); // Refresh the signals list
    } catch (error) {
      console.error("Error adding new signal:", error);
    }
  };
  const handleDelete = async () => {
    try {
      await api.deleteRequest(`api/v1/trade-signals/${signal.id}`, user.token);
      fetchData();
      handleDeleteModal();
    } catch (error) {
      console.error("Error adding new signal:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteModal = (signal) => {
    setOpenDelete(!openDelete);
    setSignal(signal);
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        {" "}
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Add New Signal
        </Button>
      </Box>
      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="90vh"
        >
          <CircularProgress />
          <Typography variant="h6" style={{ marginTop: "16px" }}>
            Loading trading signals...
          </Typography>
        </Box>
      ) : signals.length > 0 ? (
        <Box>
          <Typography variant="h6" style={{ margin: "16px 0" }}>
            Trading signals loaded successfully!
          </Typography>

          <Grid container spacing={2}>
            {signals.map((signal) => (
              <Grid item xs={12} sm={6} md={4} key={signal.id}>
                <TradingSignalCard
                  signal={signal}
                  onBuy={handleBuySignal}
                  onDelete={handleDeleteModal}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="90vh"
        >
          <Typography variant="h6">
            No trading signals available at the moment.
          </Typography>
        </Box>
      )}

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Add New Trading Signal
            </Typography>

            <TextField
              label="Symbol"
              name="symbol"
              value={newSignal.symbol}
              onChange={handleInputChange}
              fullWidth
              size="small"
              sx={{ marginTop: 1, marginBottom: 1 }}
            />

            <TextField
              label="Action"
              name="action"
              value={newSignal.action}
              onChange={handleInputChange}
              fullWidth
              size="small"
              sx={{ marginTop: 1, marginBottom: 1 }}
            />

            <TextField
              label="Entry Price"
              name="entryPrice"
              value={newSignal.entryPrice}
              onChange={handleInputChange}
              fullWidth
              size="small"
              sx={{ marginTop: 1, marginBottom: 1 }}
            />

            <TextField
              label="Target Price"
              name="targetPrice"
              value={newSignal.targetPrice}
              onChange={handleInputChange}
              fullWidth
              size="small"
              sx={{ marginTop: 1, marginBottom: 1 }}
            />

            <TextField
              label="Stop Loss"
              name="stopLoss"
              value={newSignal.stopLoss}
              onChange={handleInputChange}
              fullWidth
              size="small"
              sx={{ marginTop: 1, marginBottom: 1 }}
            />

          
            <FormControl
              fullWidth
              size="small"
              sx={{ marginTop: 1, marginBottom: 1 }}
            >
              <InputLabel id="demo-simple-select-label">Timeframe</InputLabel>
              <Select
                name="timeframe"
                labelId="Timeframe"
                id="timeframe"
                value={newSignal.timeframe}
                label="Timeframe"
                onChange={handleInputChange}
              >
                {timeframes?.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
                {/* <MenuItem value="deposit">Deposit</MenuItem>
              <MenuItem value="withdraw">Withdraw</MenuItem> */}
              </Select>
            </FormControl>
            <TextField
              label="Confidence Level"
              name="confidenceLevel"
              value={newSignal.confidenceLevel}
              onChange={handleInputChange}
              fullWidth
              size="small"
              sx={{ marginTop: 1, marginBottom: 1 }}
            />

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: 2,
              }}
            >
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleAddSignal} color="primary">
                Add Signal
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openDelete}
        onClose={handleDeleteModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={openDelete}>
          <Box sx={style}>
            {" "}
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Signal Delete
            </Typography>
            <Typography
              id="spring-modal-title"
              variant="subtitle2"
              component="h2"
            >
              Are you sure you want to delete?
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: 2,
              }}
            >
              <Button
                onClick={handleClose}
                color="secondary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                color="error"
                variant="outlined"
                sx={{ ml: 2 }}
              >
                Delete Signal
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default SignalsPageAdmin;
