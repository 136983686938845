import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../components/Logo";
import ApiService from "../../lib/api_service";
import { loginUser } from "../../store/actions/auth";

const api = new ApiService();

export default function SignUp() {
  const [signup, setSignup] = useState({
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    mobileNumber: "",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [refCodeReadOnly, setrefCodereadOnly] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const refCode = searchParams?.get("ref");

  useEffect(() => {
    if (refCode) {
      setReferralCode(refCode);
      setrefCodereadOnly(!refCodeReadOnly);
    }
  }, [refCode]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    let formaData = signup;
    if (referralCode) {
      formaData.referralCode = referralCode;
    }
    const response = await api.postRequest(
      `api/v1/auth/signup`,
      JSON.stringify(formaData)
    );
    if (!response.status) {
      setError(response.message);
      setTimeout(() => {
        setError(null);
      }, 4000);
      return;
    }
    dispatch(loginUser(response.data));
    navigate("/user/signals");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignup({ ...signup, [name]: value });
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        px: 2,
      }}
    >
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 2,
          py: 4,
          borderRadius: 2,
          width: "100%",
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            flexGrow: 1,
            p: 1,
          }}
        >
          <Box mb={2}>
            <Logo />
          </Box>
          <Typography variant="h5" gutterBottom>
            Create an account
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              variant="outlined"
              label="First Name"
              name="firstname"
              value={signup.firstname}
              onChange={handleChange}
              required
              margin="normal"
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Last Name"
              name="lastname"
              value={signup.lastname}
              onChange={handleChange}
              required
              margin="normal"
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Email"
              name="email"
              type="email"
              value={signup.email}
              onChange={handleChange}
              required
              margin="normal"
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Mobile Number"
              name="mobileNumber"
              value={signup.mobileNumber}
              onChange={handleChange}
              required
              margin="normal"
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Password"
              name="password"
              type="password"
              value={signup.password}
              onChange={handleChange}
              required
              margin="normal"
              inputProps={{
                minLength: 6,
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Referral Code"
              name="referralCode"
              type="text"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              disabled={refCodeReadOnly}
              margin="normal"
              inputProps={{
                minLength: 6,
              }}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
              mt={2}
            >
              <Link to="/signin" style={{ textDecoration: "none" }}>
                <Typography variant="body2" color="primary">
                  You already have an account?
                </Typography>
              </Link>
            </Box>
            {error && (
              <Box mb={2}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
            {success && (
              <Box mb={2}>
                <Alert severity="success">Account created successfully!</Alert>
              </Box>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                padding: "12px 0",
                fontSize: "16px",
                textTransform: "uppercase",
              }}
            >
              Sign Up
            </Button>
          </form>
        </Card>
      </Box>
    </Container>
  );
}
