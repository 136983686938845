import { CurrencyExchange, MoreVert } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Fade,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import React, { useState } from "react";
import { convertToKsh, formatCurreny } from "../lib/convertAmount";
import { useSelector } from "react-redux";
import ApiService from "../lib/api_service";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const api = new ApiService();
export default function UpdateTransactionModal({
  open,
  handleClose,
  transaction,
  type,
}) {
  const [mpesaCode, setMpesaCode] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const { user } = useSelector((st) => st.user);
  const handleSubmit = async () => {
    let res;
    if (transaction?.transactionType === "withdraw") {
      res = await api.postRequestwithBearer(
        "api/v1/payments/confirm-withdraw",
        JSON.stringify({
          id: transaction.id,
          mpesaCode,
        }),
        user.token
      );
    } else {
      res = await api.postRequestwithBearer(
        "api/v1/payments/confirm-deposit",
        JSON.stringify({
          id: transaction.id,
        }),
        user.token
      );
    }

    if (!res.status) {
      setError(res.message);
      setTimeout(() => {
        handleClose();
        setError(null);
      }, 6000);
      return;
    }
    setMessage(res.message);
    setTimeout(() => {
      handleClose();
      setMessage(null);
    }, 3000);
  };
  const handleReject = async () => {
    let res;
    if (transaction?.transactionType === "withdraw") {
      res = await api.postRequestwithBearer(
        "api/v1/payments/reject-withdraw",
        JSON.stringify({
          id: transaction.id,
          mpesaCode,
        }),
        user.token
      );
    } else {
      res = await api.postRequestwithBearer(
        "api/v1/payments/reject-deposit",
        JSON.stringify({
          id: transaction.id,
        }),
        user.token
      );
    }

    if (!res.status) {
      setError(res.message);
      setTimeout(() => {
        handleClose();
        setError(null);
      }, 6000);
      return;
    }
    setMessage(res.message);
    setTimeout(() => {
      handleClose();
      setMessage(null);
    }, 3000);
  };
  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="spring-modal-title" variant="h6" component="h2">
            Confirm Transaction
          </Typography>
          <Box sx={{ marginTop: 2 }}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ bgcolor: red[500] }}
                    alt={`${transaction?.agent?.firstname} ${transaction?.agent?.lastname}`}
                    src=""
                  />
                }
                action={
                  <IconButton aria-label="settings">
                    <MoreVert />
                  </IconButton>
                }
                title={`${transaction?.agent?.firstname} ${transaction?.agent?.lastname}`}
                subheader="Agent"
              />
              <CardContent>
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    {transaction?.agent?.email}
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    {transaction?.agent?.mobileNumber}
                  </Typography>
                </Box>
                <Typography variant="h6" color="warning.main" fontWeight="bold">
                  1 USD = {transaction?.agent?.rates} KSh
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ bgcolor: red[500] }}
                    alt={`${transaction?.user?.firstname} ${transaction?.user?.lastname}`}
                  />
                }
                action={
                  <IconButton aria-label="settings">
                    <MoreVert />
                  </IconButton>
                }
                title={`${transaction?.user?.firstname} ${transaction?.user?.lastname}`}
                subheader="Trader"
              />
              <CardContent>
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    {transaction?.user?.email}
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    {transaction?.user?.mobileNumber}
                  </Typography>
                </Box>
                <Typography variant="h6" color="warning.main" fontWeight="bold">
                  {transaction?.mpesaCode}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <Typography
                    variant="caption"
                    color="warning.main"
                    fontWeight="bold"
                  >
                    {formatCurreny({
                      currency: "USD",
                      amount: transaction?.amountUSD,
                    })}
                  </Typography>
                  <IconButton>
                    <CurrencyExchange color="success" />
                  </IconButton>{" "}
                  <Typography
                    variant="caption"
                    color="warning.main"
                    fontWeight="bold"
                  >
                    {formatCurreny({
                      currency: "KES",
                      amount: transaction?.amountKSH,
                    })}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
          {transaction?.transactionType === "withdraw" && (
            <Box sx={{ marginTop: 2 }}>
              <Card>
                <CardContent>
                  {" "}
                  <TextField
                    label="MPesa Code"
                    variant="outlined"
                    fullWidth
                    value={mpesaCode}
                    onChange={(e) => setMpesaCode(e.target.value.toUpperCase())}
                  />
                </CardContent>
              </Card>
            </Box>
          )}

          {message && (
            <Box mt={1} mb={1}>
              <Alert severity="success">{message}</Alert>
            </Box>
          )}
          {error && (
            <Box mt={1} mb={1}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginTop: 2,
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={(theme) => ({
                marginRight: 4,
                color: "warning",
                textTransform: "uppercase",
              })}
            >
              reject
            </Button>

            <Button
              variant="outlined"
              onClick={handleSubmit}
              sx={{ textTransform: "uppercase" }}
            >
              confirm
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
