import React from "react";
import ApiService from "../lib/api_service";
import { useSelector } from "react-redux";
import { Switch } from "@mui/material";

const api = new ApiService();
export default function BlockUser({ row, refectUser }) {
  const { user } = useSelector((st) => st.user);
  const handleBlockToggle = async () => {
    const response = await api.postRequestwithBearer(
      row?.isBlocked
        ? `api/v1/users/reactivate/${row.id}`
        : `api/v1/users/deactivate/${row.id}`,
      null,
      user.token
    );
    if (response.status) {
      refectUser();
    }
  };
  return (
    <Switch
      checked={row?.isBlocked}
      onChange={handleBlockToggle}
      color={row?.isBlocked ? "error" : "secondary"}
      inputProps={{ "aria-label": "block user" }}
    />
  );
}
