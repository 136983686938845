import {
  Backdrop,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../lib/api_service";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const api = new ApiService();
const CreateCampaignModal = ({ open, onClose, refetch }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState(null);
  const { user } = useSelector((st) => st.user);

  const handleCreateCampaign = async () => {
    api
      .postRequestwithBearer(
        "api/v1/campaigns/create",
        JSON.stringify({ title, description }),
        user.token
      )
      .then((response) => {
        refetch();
        onClose();
      })
      .catch((error) => {
        console.error("Error creating campaign:", error);
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography variant="h6">Create New Campaign</Typography>
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            minRows={4}
          />
          <Button
            variant="contained"
            onClick={handleCreateCampaign}
            sx={{ marginTop: 2 }}
          >
            Create
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateCampaignModal;
