import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function TradeTimeWidget({ trade, refetchData }) {
  const [timeRemaining, setTimeRemaining] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const timePassed = Math.floor(
        (Date.now() - new Date(trade?.createdAt)) / 1000
      );
      const period = trade?.signal?.timeframe?.period;
      const periodInSecs = Number.parseInt(period) * 60;
      const timeLeft = periodInSecs - timePassed;
      if (timeLeft >= 0) {
        setTimeRemaining(timeLeft);
      } else {
        clearInterval(interval);
        // refetchData();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [trade]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}m ${secs}s`;
  };

  return (
    <Typography variant="body2" color="textSecondary">
      {formatTime(timeRemaining)} remaining
    </Typography>
  );
}
