import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../../lib/api_service";
import { DateTime } from "luxon";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const api = new ApiService();
const TimeIntervals = () => {
  const [agents, setAgents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [selectedAgent, setSelectedAgent] = useState(null);
  const user = useSelector((st) => st.user).user;
  const [formData, setFormData] = useState({
    name: "",
    period: "",
    rate: "",
  });

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    try {
      const response = await api.getRequest("api/v1/timeframes", user.token);
      setAgents(response.data);
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (editMode) {
        await api.putRequestwithBearer(
          `api/v1/timeframes/${selectedAgent.id}`,
          JSON.stringify(formData),
          user.token
        );
      } else {
        await api.postRequestwithBearer(
          "api/v1/timeframes",
          JSON.stringify(formData),
          user.token
        );
      }
      fetchAgents();
      setOpenModal(false);
      setFormData({
        name: "",
        period: "",
        rate: "",
      });
      setEditMode(false);
    } catch (error) {
      console.error("Error saving agent:", error);
    }
  };

  const handleEdit = (agent) => {
    setSelectedAgent(agent);
    setFormData(agent);
    setEditMode(true);
    setOpenModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await api.deleteRequest(`api/v1/timeframes/${id}`);
      fetchAgents();
    } catch (error) {
      console.error("Error deleting agent:", error);
    }
  };

  const columns = [
    // { field: "id", headerName: "ID", minWidth: 150 },
    { field: "name", headerName: "Name", minWidth: 150, flex: 1 },
    { field: "period", headerName: "Time Interval", minWidth: 150, flex: 1 },
    { field: "rate", headerName: "Rates", minWidth: 150, flex: 1 },
    {
      field: "updatedAt",
      headerName: "Updated At",
      editable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) =>
        DateTime.fromISO(params.row.updatedAt).toLocaleString(
          DateTime.DATETIME_MED
        ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      editable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) =>
        DateTime.fromISO(params.row.createdAt).toLocaleString(
          DateTime.DATETIME_MED
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 150,
      type: "actions",
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEdit(params.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={() => handleDelete(params.row.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div style={{}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        {" "}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          Add Time Interval
        </Button>
      </Box>
      <Card
        variant="outlined"
        sx={{
          height: "100%",
          width: "100%",
          display: { xs: "none", sm: "flex" },
          pointerEvents: "none",
        }}
      >
        <DataGrid
          rows={agents}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{ width: "100%" }}
        />
      </Card>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={style}>
          <Typography variant="h6" gutterBottom>
            {editMode ? "Edit Agent" : "Add Agent"}
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Period"
            name="period"
            placeholder="60"
            value={formData.period}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Rate"
            name="rate"
            placeholder="0.08"
            value={formData.rate}
            onChange={handleInputChange}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ mt: 2 }}
          >
            {editMode ? "Update" : "Create"}
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default TimeIntervals;
